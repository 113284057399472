@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Radikal Heading';
    src: url('./lib/fonts/nootype_radikal_medium.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Radikal Bold';
    src: url('./lib/fonts/nootype_radikal_bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Radikal Body';
    src: url('./lib/fonts/nootype_radikal.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Radikal Button';
    src: url('./lib/fonts/nootype_radikal_light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  body {
    @apply font-body m-0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    @apply font-button;
  }
}

.prevent-zoom {
  font-size: 16px;
}
